import { ChainID } from '@/constants'

type SupportChain = ChainID.A8 | ChainID.Etherlink

type BlockscoutConfig = {
  basePath?: string
}

type Config = Record<SupportChain, BlockscoutConfig>

const configs: Config = {
  [ChainID.A8]: {
    basePath: process.env.NEXT_PUBLIC_A8_SCAN_V2_URL,
  },
  [ChainID.Etherlink]: {
    basePath: process.env.NEXT_PUBLIC_ETHERLINK_SCAN_URL,
  },
}

export default configs
