import {
  DeleteSocialDto,
  S3UserData,
  S3UserUpdateDto,
  UpdateUserSocial,
} from '@/types/user'

import { coreApi as api } from '../base-axios/coreApi'

const CONTROLLER = '/user'

class SP3User {
  async getOne(): Promise<S3UserData> {
    const { data } = await api.get<S3UserData>(`${CONTROLLER}`)
    return data
  }

  async update(payload: S3UserUpdateDto) {
    const { data } = await api.put<S3UserData>(`${CONTROLLER}`, payload)
    return data
  }

  async removeAuthCache() {
    await api.delete(`${CONTROLLER}/auth-cache`)
  }

  async removeProfileCache() {
    await api.delete(`${CONTROLLER}/profile-cache`)
  }

  async updateUserSocialInfo({ type, info }: UpdateUserSocial) {
    const { data } = await api.patch(`${CONTROLLER}/social`, {
      type,
      info,
    })
    return data
  }
  async deleteSocial({ type }: DeleteSocialDto) {
    const { data } = await api.delete(`${CONTROLLER}/social`, {
      params: {
        type,
      },
    })
    return data
  }

  async updateMember() {
    await api.post(CONTROLLER + '/join')
  }
}

export const SP3UserService = new SP3User()
