import { ChainID } from '@/constants'

import type { GachaSupportChains } from '@/types/gacha/gacha.type'

/**
 * Constructor
 */

type Config = {
  openEditionContract: string
  gachaPaymentContract: Record<GachaSupportChains, string>
  gachaStationContract: Record<GachaSupportChains, string>
  votingUsElection: string
}

const configs: Config = {
  openEditionContract: process.env
    .NEXT_PUBLIC_A8_EDITION_CONTRACT_ADDRESS as string,
  gachaPaymentContract: {
    [ChainID.A8]: process.env
      .NEXT_PUBLIC_GACHA_A8_PAYMENT_CONTRACT_ADDRESS as string,
    [ChainID.Etherlink]: process.env
      .NEXT_PUBLIC_GACHA_ETHERLINK_PAYMENT_CONTRACT_ADDRESS as string,
  },
  gachaStationContract: {
    [ChainID.A8]: process.env
      .NEXT_PUBLIC_GACHA_A8_STATION_CONTRACT_ADDRESS as string,
    [ChainID.Etherlink]: process.env
      .NEXT_PUBLIC_GACHA_ETHERLINK_STATION_CONTRACT_ADDRESS as string,
  },
  votingUsElection: process.env
    .NEXT_PUBLIC_VOTING_US_ELECTION_ADDRESS as string,
}

/**
 * Module exports
 */
export default configs
